#forgetPassword {
  background-color: #f6f9fc;
  background-size: cover;
}

#forgetPassword,
#changePassword {
  .content_box {
    min-height: calc(100vh - 96px) !important;
    padding-top: 140px;
  }

  h2 {
    color: $primary-purple;
  }

  .meta_box {
    .changePassword_box {
      .change_form {
        .el-form-item {
          width: 400px;

          &:not(:first-child) {
            margin-right: 60px;
            display: inline-block;
          }
        }

        .btn_item {
          padding-top: 50px;
        }

        .error {
          color: red;
          margin-top: 40px;
          text-align: center;
          font-weight: bold;
        }

        .password_error {
          margin-bottom: 80px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  #forgetPassword,
  #changePassword {
    .meta_box {
      width: 80%;
    }
  }
}

@media (max-width: 768px) {
  #changePassword,
  #forgetPassword {
    .content_box {
      min-height: calc(100vh - 71px - 140px) !important;
    }

    .meta_box .changePassword_box,
    .meta_box .forgetPassword_box {
      .change_form {
        max-width: 70%;
      }
    }
  }
  .newPassword {
    /deep/.el-input {
      margin-top: 10px;
    }
  }
}

@media (max-width: 550px) {
  #forgetPassword,
  #changePassword {
    .meta_box {
      padding: 45px 20px 24px;
      width: 100%;

      .changePassword_box,
      .forgetPassword_box {
        .change_form {
          max-width: 100%;
          text-align: center;

          .el-form-item {
            width: 250px;
            display: inline-block;
            margin-right: 0 !important;

            &:not(:first-child) {
              margin-top: 20px;
            }
          }
        }

        .btn_item {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .newPassword {
    /deep/.el-input {
      margin-top: 45px;
    }
  }
}
